<template>
  <div class="mpWaybillList">
    <!-- <el-breadcrumb separator="/" style="margin: 10px 0px">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>待开运单</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- 表格区域 -->

    <div style="max-height:84.5vh">
      <!-- <el-tabs v-model="activeCmpt" @tab-click="cmptChange">
        <el-tab-pane label="小程序运单" name="1"></el-tab-pane>
        <el-tab-pane label="其他运单" name="2"></el-tab-pane>
      </el-tabs> -->
      <div>
        <div class="topbtns">
          <el-button icon="el-icon-download" type="primary" size="medium" @click="exportExcel">导出表格</el-button>
        </div>

        <el-form class="searchForm" :model="searchForm" ref="searchForm">

          <div style="display: flex; flex-wrap: wrap;">
            <div style="display: flex; flex-wrap: wrap;">
              <el-form-item label="企业名称：" prop="keyword" label-width="120px">
                <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.companyName"
                  placeholder="请输入企业名称"></el-input>
              </el-form-item>
              <el-form-item label="司机手机号：" prop="keyword" label-width="120px">
                <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.driverPhone"
                  placeholder="请输入司机手机号">
                </el-input>
              </el-form-item>
            </div>
            <div style="display: flex; flex-wrap: wrap;">
              <el-form-item label="收款人手机号：" prop="keyword" label-width="120px">
                <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.receivePhone"
                  placeholder="请输入收款人手机号">
                </el-input>
              </el-form-item>
              <el-form-item label="车牌号：" prop="keyword" label-width="120px">
                <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.carNumber"
                  placeholder="请输入车牌号">
                </el-input>
              </el-form-item>

              <el-form-item label-width="10px">
                <div style="white-space:nowrap;">
                  <el-button type="primary" size="medium" @click="search('searchForm')"
                    icon="el-icon-search">搜索</el-button>
                  <el-button type="primary" size="medium" icon="el-icon-delete"
                    @click="resetForm('searchForm')">清空</el-button>
                  <el-button type="primary" size="medium" :icon="showSelect ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                    @click="isShowSelect">更多选项</el-button>
                </div>
              </el-form-item>
            </div>
          </div>
          <!-- <el-form-item label="运单号：" prop="keyword" label-width="130px">
          <el-input v-model="searchForm.wayBillID" placeholder="请输入运单号">
          </el-input>
        </el-form-item> -->
          <el-collapse-transition>
            <div style="display: flex; flex-wrap: nowrap;" v-show="showSelect">
              <el-form-item label="起始地：" label-width="120px">
                <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.startAddress"
                  placeholder="请输入起始地">
                </el-input>
              </el-form-item>
              <el-form-item label="到达地：" label-width="120px">
                <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.arriveAddress"
                  placeholder="请输入到达地">
                </el-input>
              </el-form-item>
              <!-- <el-form-item label="场景类型：" prop="keyword" label-width="130px">

          <el-select @change="changeType" v-model="searchForm.type" placeholder="请选择场景类型" style="width: 208px">
            <el-option v-for="item in sceneList" :key="item.Code" :label="item.Name" :value="item.Code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="进度状态：" prop="keyword" label-width="130px">
          <el-select @change="changeProgress" v-model="searchForm.status" placeholder="请选择进度状态" style="width: 208px">
            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item> -->
              <el-form-item label="签收时间：" prop="keyword" label-width="120px">
                <el-date-picker @change="search" v-model="searchForm.time" type="datetimerange"
                  start-placeholder="请选择开始时间" end-placeholder="请选择结束时间" value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </el-form-item>
            </div>
          </el-collapse-transition>
        </el-form>
        <el-table :data="tableData" v-loading="loading" :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
          <el-table-column fixed align="center" type="index" prop="index" label="序号"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="WaybillID" label="运单号" width="200"
            show-overflow-tooltip></el-table-column>
          <!-- <el-table-column align="center" prop="InvoiceTypeName" label="发票类型" width="200"></el-table-column>
        <el-table-column align="center" prop="TaskTypeName" label="业务类型" width="200"></el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200"></el-table-column> -->
          <el-table-column align="center" prop="CarriageBillID" label="客户单号" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="GoodsOwnerName" label="货主单位" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="DriverName" label="司机姓名" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="DriverPhone" label="司机手机号码" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                {{ scope.row.DriverPhone }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="PayeeName" label="收款人姓名" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="PayeePhone" label="收款人手机号码" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="CarNumber" label="车牌" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                {{ scope.row.CarNumber }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="LoadNetWeight" label="装货净重(吨)" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="GoodsName" label="货品名称" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="GoodsUnit" label="货品单位" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="LoadingDatetime" label="装货时间" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="SignDatetime" label="签收时间" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="OriginAddress" label="起始地" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                {{ scope.row.OriginAddress }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="ArriveAddress" label="到达地" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                {{ scope.row.ArriveAddress }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="DriverAllotCount" label="司机装货数量" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="DriverCarryPrice" label="司机运输单价（人民币）" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{ scope.row.DriverCarryPrice | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="CarriageTotalPrice" label="运费" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{ scope.row.CarriageTotalPrice | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{ scope.row.ExpensesOfTaxation | formatMoney }}
              </div>

            </template>
          </el-table-column>
          <el-table-column align="center" prop="TotalAmount" label="运单总金额" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{ scope.row.TotalAmount | formatMoney }}
              </div>
            </template>
          </el-table-column>

          <el-table-column align="center" prop="TotalTransCost" label="运输总成本" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{ scope.row.TotalTransCost | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="TrailerNumber" label="挂车车牌" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="UnloadNetWeight" label="卸货净重(吨)" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="OwnerContactsName" label="货主联系人" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="OwnerContactsPhone" label="货主联系人手机号码" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="CarrierName" label="承运商单位" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="CarrierContactsName" label="承运商联系人" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="CarrierContactsPhone" label="承运商联系人手机号码" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="CarriagePrice" label="承运运输单价（人民币）" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{ scope.row.CarriagePrice | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="AllowLossRate" label="允许损耗率(‰)" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="DrawnUnit" label="提货单位" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="DrawnContactsPhone" label="提货联系人手机号码" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="DrawnAddress" label="提货地址" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="DrawnContactsName" label="提货联系人" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="TakeGoodsUnit" label="卸货单位" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="UnloadGoodsContactsPhone" label="卸货联系人手机号码" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="UnloadGoodsAddress" label="卸货地址" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="UnloadGoodsContactsName" label="卸货联系人" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="SignBillID" label="签收单号" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="HandworkBillID" label="手工单号" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="BillDatetime" label="单据时间" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="OriginalWayBillID" label="溯源运单号" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="220">
            <template slot-scope="scope">
              <el-button type="primary" size="small" icon="el-icon-edit-outline"
                @click="editOrder(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
          <!-- <el-table-column align="center" prop="TaskStatus" label="运单状态" width="200">
           <template slot-scope="scope">
            <div>
              <el-tag :type="scope.row.TaskStatus == 3 ? 'success' : 'danger'"> {{scope.row.TaskStatus == 3  ? '已开票' : '未开票'}}</el-tag>
            </div>
          </template>
        </el-table-column> -->
        </el-table>

        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange"
          :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>
    <!-- 编辑运单dialog -->
    <el-dialog class="vehicleType" :visible.sync="showEditDialog" width="950px" style="margin-top: 1vh;">
      <span slot="title" class="dialog-title">
        编辑运单
      </span>
      <div class="body">
        <div style="height: 60vh;overflow-y:auto">

          <el-form class="addForm" :model="addForm" ref="addForm" :rules="addRule" label-width="157px">

            <el-form-item label="货主单位" prop="goodsOwnerName">
              <el-input v-model="addForm.goodsOwnerName" placeholder="请输入货主单位"></el-input>
            </el-form-item>
            <el-form-item label="司机姓名" prop="driverName">
              <el-input v-model="addForm.driverName" placeholder="请输入司机姓名"></el-input>
            </el-form-item>
            <el-form-item label="司机手机号码" prop="driverPhone">
              <el-input v-model="addForm.driverPhone" placeholder="请输入司机手机号码"></el-input>
            </el-form-item>
            <el-form-item label="收款人姓名" prop="payeeName">
              <el-input v-model="addForm.payeeName" placeholder="请输入收款人姓名"></el-input>
            </el-form-item>
            <el-form-item label="收款人手机号码" prop="payeePhone">
              <el-input v-model="addForm.payeePhone" placeholder="请输入收款人手机号码"></el-input>
            </el-form-item>
            <el-form-item label="车牌" prop="carNumber">
              <el-input v-model="addForm.carNumber" placeholder="请输入车牌"></el-input>
            </el-form-item>
            <el-form-item label="装货净重(吨)" prop="loadNetWeight">
              <el-input v-model="addForm.loadNetWeight" placeholder="请输入装货净重"></el-input>
            </el-form-item>
            <el-form-item label="货品名称" prop="goodsName">
              <el-input v-model="addForm.goodsName" placeholder="请输入货品名称"></el-input>
            </el-form-item>
            <el-form-item label="货品单位" prop="goodsUnit">
              <el-select v-model="addForm.goodsUnit" clearable placeholder="请选择货品单位" style="width:292px">
                <el-option v-for="item in goodsUnitList" :key="item.Name" :label="item.Name"
                  :value="item.Code"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="装货时间" prop="loadingDatetime">
              <el-date-picker v-model="addForm.loadingDatetime" type="datetime" format="yyyy-MM-dd HH:mm"
                placeholder="请选择装货时间" value-format="yyyy-MM-dd HH:mm">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="签收时间" prop="signDatetime">
              <el-date-picker v-model="addForm.signDatetime" type="datetime" format="yyyy-MM-dd HH:mm"
                placeholder="请选择签收时间" value-format="yyyy-MM-dd HH:mm">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="起始地" prop="originAddress">
              <el-input v-model="addForm.originAddress" placeholder="必须填写省、市、区道路精确到地址"></el-input>
            </el-form-item>
            <el-form-item label="到达地" prop="arriveAddress">
              <el-input v-model="addForm.arriveAddress" placeholder="必须填写省、市、区道路精确到地址"></el-input>
            </el-form-item>
            <el-form-item label="司机装货数量" prop="driverAllotCount">
              <el-input v-model="addForm.driverAllotCount" placeholder="请输入司机装货数量"></el-input>
            </el-form-item>
            <el-form-item label="司机运输单价(人民币)" prop="driverCarryPrice">
              <el-input v-model="addForm.driverCarryPrice" placeholder="请输入司机运输单价"></el-input>
            </el-form-item>
            <el-form-item label="运费" prop="carriageTotalPrice">
              <el-input v-model="addForm.carriageTotalPrice" placeholder='数字精确到小数点后两位;"数量x单价=运费"'></el-input>
            </el-form-item>
            <!--非必填项-->
            <el-form-item label="运输总成本" prop="TotalTransCost">
              <el-input v-model="addForm.TotalTransCost" placeholder="数字精确到小数点后两位"></el-input>
            </el-form-item>
            <el-form-item label="挂车车牌" prop="trailerNumber">
              <el-input v-model="addForm.trailerNumber" placeholder="请输入所用挂车车牌号码"></el-input>
            </el-form-item>
            <el-form-item label="卸货净重(吨)" prop="unloadNetWeight">
              <el-input v-model="addForm.unloadNetWeight" placeholder="数字精确到小数点后两位"></el-input>
            </el-form-item>
            <el-form-item label="货主联系人" prop="ownerContactsName">
              <el-input v-model="addForm.ownerContactsName" placeholder="请输入实际货主联系人名字"></el-input>
            </el-form-item>
            <el-form-item label="货主联系人手机号码" prop="ownerContactsPhone">
              <el-input v-model="addForm.ownerContactsPhone" placeholder="请输入实际货主联系人对应的手机号码"></el-input>
            </el-form-item>

            <el-form-item label="承运商名称" prop="carrierName">
              <el-input v-model="addForm.carrierName" placeholder="请输入完整的承运商名称"></el-input>
            </el-form-item>
            <el-form-item label="承运商联系人" prop="carrierContactsName">
              <el-input v-model="addForm.carrierContactsName" placeholder="请输入承运商联系人名字"></el-input>
            </el-form-item>
            <el-form-item label="承运商手机号" prop="carrierContactsPhone">
              <el-input v-model="addForm.carrierContactsPhone" placeholder="请输入承运商对应的手机号码"></el-input>
            </el-form-item>
            <el-form-item label="承运运输单价(人民币)" prop="carriagePrice">
              <el-input v-model="addForm.carriagePrice" placeholder="数字精确到小数点后两位"></el-input>
            </el-form-item>
            <el-form-item label="允许损耗率(%)" prop="allowLossRate">
              <el-input v-model="addForm.allowLossRate" placeholder="数字精确到小数点后两位"></el-input>
            </el-form-item>
            <el-form-item label="提货单位" prop="drawnUnit">
              <el-input v-model="addForm.drawnUnit" placeholder="请输入完整的提货单位"></el-input>
            </el-form-item>
            <el-form-item label="提货联系人手机号码" prop="drawnContactsPhone">
              <el-input v-model="addForm.drawnContactsPhone" placeholder="请输入提货联系人对应的手机号码"></el-input>
            </el-form-item>
            <el-form-item label="提货地址" prop="drawnAddress">
              <el-input v-model="addForm.drawnAddress" placeholder="必须填写省、市、区道路精确到地址"></el-input>
            </el-form-item>
            <el-form-item label="提货联系人" prop="drawnContactsName">
              <el-input v-model="addForm.drawnContactsName" placeholder="请输入提货联系人名字"></el-input>
            </el-form-item>
            <el-form-item label="卸货单位" prop="TakeGoodsUnit">
              <el-input v-model="addForm.TakeGoodsUnit" placeholder="请输入完整的卸货单位名称"></el-input>
            </el-form-item>
            <el-form-item label="卸货联系人手机号码" prop="unloadGoodsContactsPhone">
              <el-input v-model="addForm.unloadGoodsContactsPhone" placeholder="请填写卸货联系人对应的手机号码"></el-input>
            </el-form-item>
            <el-form-item label="卸货地址" prop="unloadGoodsAddress">
              <el-input v-model="addForm.unloadGoodsAddress" placeholder="必须填写省、市、区道路精确到地址"></el-input>
            </el-form-item>
            <el-form-item label="卸货联系人" prop="unloadGoodsContactsName">
              <el-input v-model="addForm.unloadGoodsContactsName" placeholder="请填写卸货联系人名字"></el-input>
            </el-form-item>
            <el-form-item label="签收单号" prop="signBillID">
              <el-input v-model="addForm.signBillID" placeholder="按实际情况填写"></el-input>
            </el-form-item>
            <el-form-item label="手工单号" prop="handworkBillID">
              <el-input v-model="addForm.handworkBillID" placeholder="按实际情况填写"></el-input>
            </el-form-item>
            <el-form-item label="单据时间" prop="BillDatetime">
              <el-date-picker v-model="addForm.BillDatetime" type="datetime" format="yyyy-MM-dd HH:mm"
                placeholder="按实际情况填写" value-format="yyyy-MM-dd HH:mm">
              </el-date-picker>
            </el-form-item>

          </el-form>
          <div class="picArr1">
            <div class="title">人车合影</div>
            <div class="upload">
              <el-upload accept=".jpg,.jpeg,.png,.JPG,.JPEG" :on-change="uploadChange" :action="host"
                :on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :on-success="handleUploadSuccess"
                list-type="picture-card" :on-remove="handleRemove" :class="{ hide: uploadDisabled1 }"
                :file-list="carAndPeopleImg" :limit="limitNum">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </div>
          </div>
          <!--支付凭证-->
          <div class="picArr1">
            <div class="title">支付凭证</div>
            <div class="upload">
              <el-upload accept=".jpg,.jpeg,.png,.JPG,.JPEG" :on-change="uploadChange1" :action="host"
                :on-preview="handlePictureCardPreview1" :on-exceed="handleExceed1" :on-success="handleUploadSuccess1"
                list-type="picture-card" :on-remove="handleRemove1" :class="{ hide: uploadDisabled2 }"
                :file-list="payImgList" :limit="limitNum">
                <i class="el-icon-plus"></i>

              </el-upload>
              <el-dialog :visible.sync="dialogVisible1">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button size="medium" @click="cancelModel">取消</el-button>
        <el-button type="primary" size="medium" @click="editRow('addForm')">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getMpBillList, mpAllBillDwon, updatewayBill, getMpImgInfo } from '@/api/waybill/otherAndAllBill/index'
import { getDataDict } from '@/api/common/common'
import { host } from '@/api/config/host'
export default {
  data() {
    // 正则校验规则
    var validator1 = (rule, value, callback) => {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error('请输入正确的司机手机号码'));
      } else {
        callback();
      }
    };

    var validator2 = (rule, value, callback) => {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error('请输入正确的收货人手机号码'));
      } else {
        callback();
      }
    };
    var validator3 = (rule, value, callback) => {
      if (!/^([1-9][0-9]*)+(\.[0-9]{1,2})?$/.test(value)) {
        callback(new Error('请正确的装货净重'));
      } else {
        callback();
      }
    };
    var validator4 = (rule, value, callback) => {
      if (!/^([1-9][0-9]*)+(\.[0-9]{1,2})?$/.test(value)) {
        callback(new Error('请输入正确的装货数量'));
      } else {
        callback();
      }
    };
    var validator5 = (rule, value, callback) => {
      if (!/^([1-9][0-9]*)+(\.[0-9]{1,2})?$/.test(value)) {
        callback(new Error('请输入正确的运输单价'));
      } else {
        callback();
      }
    };
    var validator6 = (rule, value, callback) => {
      if (!/^([1-9][0-9]*)+(\.[0-9]{1,2})?$/.test(value)) {
        callback(new Error('请输入正确的运费'));
      } else {
        callback();
      }
    };
    // 非必填验证
    var validator7 = (rule, value, callback) => {
      if (value) {
        if (!/^([1-9][0-9]*)+(\.[0-9]{1,2})?$/.test(value)) {
          callback(new Error('请输入正确运输总成本'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validator8 = (rule, value, callback) => {
      if (value) {
        if (!/^([1-9][0-9]*)+(\.[0-9]{1,2})?$/.test(value)) {
          callback(new Error('请输入正确的卸货净重'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validator9 = (rule, value, callback) => {
      if (value) {
        if (!/^1[3456789]\d{9}$/.test(value)) {
          callback(new Error('请输入正确的货主手机号码'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validator10 = (rule, value, callback) => {
      if (value) {
        if (!/^1[3456789]\d{9}$/.test(value)) {
          callback(new Error('请输入正确的承运商手机号码'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validator11 = (rule, value, callback) => {
      if (value) {
        if (!/^([1-9][0-9]*)+(\.[0-9]{1,2})?$/.test(value)) {
          callback(new Error('请输入正确的承运运输单价'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validator12 = (rule, value, callback) => {
      if (value) {
        if (!/^([1-9][0-9]*)+(\.[0-9]{1,2})?$/.test(value)) {
          callback(new Error('请输入正确的允许损耗率'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validator13 = (rule, value, callback) => {
      if (value) {
        if (!/^1[3456789]\d{9}$/.test(value)) {
          callback(new Error('请输入正确的提货人手机号码'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validator14 = (rule, value, callback) => {
      if (value) {
        if (!/^1[3456789]\d{9}$/.test(value)) {
          callback(new Error('请输入正确的卸货人手机号码'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    return {
      host: host + '/api/Image/Upload/Img', // 上传图片的地址
      pagination: { //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      addForm: {
        waybillID: '', // 修改的运单ID
        // 必填字段
        driverName: '',
        driverPhone: '',
        carNumber: '',
        originAddress: '',
        loadingDatetime: '',
        arriveAddress: '',
        signDatetime: '',
        loadNetWeight: '',
        goodsOwnerName: '',
        goodsName: '',
        goodsUnit: '',
        payeeName: '',
        payeePhone: '',
        driverAllotCount: '',
        driverCarryPrice: '',
        carriageTotalPrice: '',
        // 非必填字段				
        TotalTransCost: '',
        trailerNumber: '',
        unloadNetWeight: '',
        ownerContactsName: '',
        ownerContactsPhone: '',
        carrierName: '',
        carrierContactsName: '',
        carrierContactsPhone: '',
        carriagePrice: '',
        allowLossRate: '',
        drawnUnit: '',
        drawnContactsPhone: '',
        drawnAddress: '',
        drawnContactsName: '',
        TakeGoodsUnit: '',
        unloadGoodsContactsPhone: '',
        unloadGoodsAddress: '',
        unloadGoodsContactsName: '',
        signBillID: '',
        handworkBillID: '',
        BillDatetime: ''

      },
      addRule: {
        goodsOwnerName: [{ required: true, message: '请输入货主单位', trigger: 'blur' }],
        driverName: [{ required: true, message: '请输入司机姓名', trigger: 'blur' }],
        driverPhone: [{ required: true, validator: validator1, trigger: 'blur' }],
        payeeName: [{ required: true, message: '请输入收款人姓名', trigger: 'blur' }],
        payeePhone: [{ required: true, validator: validator2, trigger: 'blur' }],
        carNumber: [{ required: true, message: '请输入车牌', trigger: 'blur' }],
        loadNetWeight: [{ required: true, validator: validator3, trigger: 'blur' }],
        goodsName: [{ required: true, message: '请输入货品名称', trigger: 'blur' }],
        goodsUnit: [{ required: true, message: '请选择货品单位', trigger: 'change' }],
        loadingDatetime: [{ required: true, message: '请选择装货时间', trigger: 'change' }],
        signDatetime: [{ required: true, message: '请选择签收时间', trigger: 'change' }],
        originAddress: [{ required: true, message: '请填写起始地', trigger: 'blur' }],
        arriveAddress: [{ required: true, message: '请填写到达地', trigger: 'blur' }],
        driverAllotCount: [{ required: true, validator: validator4, trigger: 'blur' }],
        driverCarryPrice: [{ required: true, validator: validator5, trigger: 'blur' }],
        carriageTotalPrice: [{ required: true, validator: validator6, trigger: 'blur' }],
        TotalTransCost: [{ validator: validator7, trigger: 'blur' }],
        unloadNetWeight: [{ validator: validator8, trigger: 'blur' }],
        ownerContactsPhone: [{ validator: validator9, trigger: 'blur' }],
        carrierContactsPhone: [{ validator: validator10, trigger: 'blur' }],
        carriagePrice: [{ validator: validator11, trigger: 'blur' }],
        allowLossRate: [{ validator: validator12, trigger: 'blur' }],
        drawnContactsPhone: [{ validator: validator13, trigger: 'blur' }],
        unloadGoodsContactsPhone: [{ validator: validator14, trigger: 'blur' }],
      },
      // 搜索表单
      searchForm: {
        companyName: '', // 企业名称
        driverPhone: '', // 司机手机号
        receivePhone: '', // 收款人手机号
        carNumber: '', // 车牌
        // wayBillID: '', // 运单号
        time: '',// 时间
        //  type: '01', // 场景类型 默认为查找承运业务
        //  status: '0', // 进度状态
        startAddress: '', // 起始地
        arriveAddress: '' // 到达地
      },
      tableData: [], //表格数据
      sceneList: [], //场景类型
      statusList: [
        {
          value: '0',
          label: '全部'
        },
        {
          value: '1',
          label: '未完成流程'
        },
        {
          value: '2',
          label: '已完成流程'
        }
      ], // 进度状态
      // 是否禁用提交按钮
      // showSubmit: false,
      loading: false,
      showEditDialog: false, // 显示编辑弹框
      goodsUnitList: [], // 货品单位数据字典
      carAndPeopleImg: [], // 人车合影图片
      payImgList: [], // 支付凭证图片
      limitNum: 2, // 图片上传数量
      uploadDisabled1: false,  // 支付凭证超出图片上传限制隐藏上传框
      dialogVisible: false, // 人车合影显示查看大图的标识
      dialogVisible1: false,// 支付凭证显示查看大图的标识
      uploadDisabled2: false, // 人车合影超出图片上传限制隐藏上传框
      dialogImageUrl: '', // 展示放大查看的图片
      showSelect: false, // 展开隐藏搜索框
    }
  },

  methods: {
    // 隐藏/显示筛选框
    isShowSelect() {
      this.showSelect = !this.showSelect
    },
    //搜索
    search(formName) {

      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //搜索前初始化page
      this.pagination.page = 1
      this.loading = true
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        //   taskType: this.searchForm.type,
        //   wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        carNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        importTimeStart: this.searchForm.time[0],
        importTimeEnd: this.searchForm.time[1]
      }
      getMpBillList(params).then(res => {
        this.tableData = res.data.DataList
        this.pagination.total = Number(res.data.TotalCount)
      }).finally(() => {
        this.loading = false;
      })
      //   }
      //   })
    },
    // 编辑提交
    editRow(formName) {
      this.$refs[formName].validate((valid) => {

        if (valid) {
          let params = {
            waybill: {

            },
            evidences: [],
          }
          // 方法与小程序一致
          params.waybill = {
            waybillID: this.addForm.waybillID,
            driverName: this.addForm.driverName,
            driverPhone: this.addForm.driverPhone,
            carNumber: this.addForm.carNumber,
            originAddress: this.addForm.originAddress,
            loadingDatetime: this.addForm.loadingDatetime,
            arriveAddress: this.addForm.arriveAddress,
            signDatetime: this.addForm.signDatetime,
            loadNetWeight: this.addForm.loadNetWeight,
            goodsOwnerName: this.addForm.goodsOwnerName,
            goodsName: this.addForm.goodsName,
            goodsUnit: this.addForm.goodsUnit,
            payeeName: this.addForm.payeeName,
            payeePhone: this.addForm.payeePhone,
            driverAllotCount: this.addForm.driverAllotCount,
            driverCarryPrice: this.addForm.driverCarryPrice,
            carriageTotalPrice: this.addForm.carriageTotalPrice,
            // 非必填项					
            TotalTransCost: this.addForm.TotalTransCost ? this.addForm.TotalTransCost : 0,
            trailerNumber: this.addForm.trailerNumber,
            unloadNetWeight: this.addForm.unloadNetWeight ? this.addForm.unloadNetWeight : 0,
            ownerContactsName: this.addForm.ownerContactsName,
            ownerContactsPhone: this.addForm.ownerContactsPhone,
            carrierName: this.addForm.carrierName,
            carrierContactsName: this.addForm.carrierContactsName,
            carrierContactsPhone: this.addForm.carrierContactsPhone,
            carriagePrice: this.addForm.carriagePrice ? this.addForm.carriagePrice : 0,
            allowLossRate: this.addForm.allowLossRate ? this.addForm.allowLossRate : 0,
            drawnUnit: this.addForm.drawnUnit,
            drawnContactsPhone: this.addForm.drawnContactsPhone,
            drawnAddress: this.addForm.drawnAddress,
            drawnContactsName: this.addForm.drawnContactsName,
            TakeGoodsUnit: this.addForm.TakeGoodsUnit,
            unloadGoodsContactsPhone: this.addForm.unloadGoodsContactsPhone,
            unloadGoodsAddress: this.addForm.unloadGoodsAddress,
            unloadGoodsContactsName: this.addForm.unloadGoodsContactsName,
            signBillID: this.addForm.signBillID,
            handworkBillID: this.addForm.handworkBillID,
            BillDatetime: this.addForm.BillDatetime
          }
          let imgArr = []

          for (let i = 0; i < this.payImgList.length; i++) {
            if (this.payImgList[i]) {
              let item = {
                evidenceType: '',
                evidenceImgUrl: '',
                remark: ''
              }
              item.evidenceImgUrl = this.payImgList[i].response ? this.payImgList[i].response.data.data : this.payImgList[i].url
              item.evidenceType = '支付凭证'
              imgArr.push(item)
            }
          }
          for (let i = 0; i < this.carAndPeopleImg.length; i++) {
            if (this.carAndPeopleImg[i]) {
              let item = {
                evidenceType: '',
                evidenceImgUrl: '',
                remark: ''
              }
              item.evidenceImgUrl = this.carAndPeopleImg[i].response ? this.carAndPeopleImg[i].response.data.data : this.carAndPeopleImg[i].url
              item.evidenceType = '人车合影'
              imgArr.push(item)
            }
          }
          params.evidences = imgArr
          updatewayBill(params).then(res => {
            this.$message.success("编辑成功")
            this.getAllBillList()
            this.payImgList = []
            this.carAndPeopleImg = []
            this.showEditDialog = false;
          }).catch(() => { })
            .finally(() => {
              this.pagination.page = 1
            })
        }
      })
    },
    //清空
    resetForm(formName) {
      this.loading = true
      this.searchForm = {
        companyName: '', // 企业名称
        driverPhone: '', // 司机手机号
        receivePhone: '', // 收款人手机号
        carNumber: '', // 车牌
        // wayBillID: '', // 运单号
        time: [],// 时间
        // type: '01', // 场景类型 默认为查找承运业务
        //  status: '0', // 进度状态
        startAddress: '', // 起始地
        arriveAddress: '' // 到达地
      }
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
        //  taskType: this.searchForm.type,
        //   wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        carNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        importTimeStart: this.searchForm.time[0],
        importTimeEnd: this.searchForm.time[1]
      }
      getMpBillList(params).then(res => {
        this.tableData = res.data.DataList
        this.pagination.total = Number(res.data.TotalCount)
      }).finally(() => {
        this.loading = false;
      })
    },
    //导出excel
    exportExcel() {
      let params = {
        //  taskType: this.searchForm.type,
        //   wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        carNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        importTimeStart: this.searchForm.time[0],
        importTimeEnd: this.searchForm.time[1]
      }
      mpAllBillDwon(params).then(res => {
        window.location.href = res.data
      })
    },
    // 编辑运单弹框
    editOrder(row) {
      // 回显运单数据
      this.addForm = {
        waybillID: row.WaybillID, // 修改的运单ID
        // 必填字段
        driverName: row.DriverName,
        driverPhone: row.DriverPhone,
        carNumber: row.CarNumber,
        originAddress: row.OriginAddress,
        loadingDatetime: row.LoadingDatetime,
        arriveAddress: row.ArriveAddress,
        signDatetime: row.SignDatetime,
        loadNetWeight: row.LoadNetWeight,
        goodsOwnerName: row.GoodsOwnerName,
        goodsName: row.GoodsName,
        goodsUnit: row.GoodsUnit,
        payeeName: row.PayeeName,
        payeePhone: row.PayeePhone,
        driverAllotCount: row.DriverAllotCount,
        driverCarryPrice: row.DriverCarryPrice,
        carriageTotalPrice: row.CarriageTotalPrice,
        // 非必填字段				
        TotalTransCost: row.TotalTransCost,
        trailerNumber: row.TrailerNumber,
        unloadNetWeight: row.UnloadNetWeight,
        ownerContactsName: row.OwnerContactsName,
        ownerContactsPhone: row.OwnerContactsPhone,
        carrierName: row.CarrierName,
        carrierContactsName: row.CarrierContactsName,
        carrierContactsPhone: row.CarrierContactsPhone,
        carriagePrice: row.CarriagePrice,
        allowLossRate: row.AllowLossRate,
        drawnUnit: row.DrawnUnit,
        drawnContactsPhone: row.DrawnContactsPhone,
        drawnAddress: row.DrawnAddress,
        drawnContactsName: row.DrawnContactsName,
        TakeGoodsUnit: row.TakeGoodsUnit,
        unloadGoodsContactsPhone: row.UnloadGoodsContactsPhone,
        unloadGoodsAddress: row.UnloadGoodsAddress,
        unloadGoodsContactsName: row.UnloadGoodsContactsName,
        signBillID: row.SignBillID,
        handworkBillID: row.HandworkBillID,
        BillDatetime: row.BillDatetime
      }
      // 获取用户上传的图片 
      let params = {
        waybillId: row.WaybillID
      }
      this.payImgList = []
      this.carAndPeopleImg = []
      getMpImgInfo(params).then(res => {
        // 人车合影 和支付凭证分开
        res.data.forEach(element => {
          if (element.EvidenceType == '人车合影') {
            let item = {
              url: element.EvidenceImgUrl
            }

            this.carAndPeopleImg.push(item)
          } else if (element.EvidenceType == '支付凭证') {
            let item = {
              url: element.EvidenceImgUrl
            }
            this.payImgList.push(item)
          }
        });
        // 如果图片大于等于2张 就隐藏上传图片框
        if (this.carAndPeopleImg.length >= this.limitNum) {
          this.uploadDisabled1 = true
        } else {
          this.uploadDisabled1 = false
        }
        if (this.payImgList.length >= this.limitNum) {
          this.uploadDisabled2 = true
        } else {
          this.uploadDisabled2 = false
        }
        this.showEditDialog = true
        //  this.$refs['addForm'].resetFields()
      })

    },
    //tbale选中改变
    tableSelectionChange(e) {
    },
    // 切换场景类型
    changeType(val) {
      this.searchForm.type = val
      this.search()
    },
    // 切换进度状态
    changeProgress(val) {
      this.searchForm.status = val
      this.search()
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: e,
        //  taskType: this.searchForm.type,
        //  wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        carNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        importTimeStart: this.searchForm.time[0],
        importTimeEnd: this.searchForm.time[1]
      }
      getMpBillList(params).then(res => {
        this.tableData = res.data.DataList
        this.pagination.total = Number(res.data.TotalCount)
      }).finally(() => {
        this.loading = false;
      })
    },
    //获取所有运单列表
    getAllBillList() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
        //  taskType: this.searchForm.type,
        //  wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        carNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        importTimeStart: this.searchForm.time[0],
        importTimeEnd: this.searchForm.time[1]
      }
      this.loading = true;
      getMpBillList(params).then(res => {
        this.tableData = res.data.DataList
        this.pagination.total = Number(res.data.TotalCount)
      }).finally(() => {
        this.loading = false;
      })
    },
    //获取场景类型列表
    getSceneList() {
      getDataDict({ type: 27 }).then(res => {
        this.sceneList = res.patterSetInfo
      })
    },
    //获取货品单位列表
    getGoodsUnitList() {
      getDataDict({ type: 28 }).then(res => {
        this.goodsUnitList = res.patterSetInfo
      })
    },

    // 关闭弹框
    cancelModel() {
      this.payImgList = []
      this.carAndPeopleImg = []
      this.showEditDialog = false
    },
    // 人车合影图片上传
    handleRemove(file, fileList) {
      this.carAndPeopleImg = fileList
      this.uploadDisabled1 = false;
    },
    //上传中
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      this.uploadDisabled1 = true;
    },
    //上传成功
    handleUploadSuccess(file) {
      let item = {
        url: file.data.data
      }
      this.carAndPeopleImg.push(item)
      this.dialogImageUrl = file.result; //专区logoId
      this.uploadDisabled1 = true;
    },
    // 支付凭证上传图片
    handleRemove1(file, fileList) {
      this.payImgList = fileList
      this.uploadDisabled2 = false;
    },
    //上传中
    handlePictureCardPreview1(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible1 = true;
      this.uploadDisabled2 = true;
    },
    //上传成功
    handleUploadSuccess1(file) {
      let item = {
        url: file.data.data
      }
      this.payImgList.push(item)
      this.dialogImageUrl = file.result; //专区logoId
      this.uploadDisabled2 = true;
    },
    // 上传超出张数限制的提示
    handleExceed(files, fileList) {
      this.$message.warning(`最多上传2张图片`);
    },
    // 上传超出张数限制的提示
    handleExceed1(files, fileList) {
      this.$message.warning(`最多上传2张图片`);
    },
    //上传限制文件类型 人车合影
    uploadChange(file, fileList) {
      // 拿到当前上传图片的name，判断其后缀名是否符合
      let type = this.isImage(file.name);
      this.carAndPeopleImg = fileList;
      let fileLength = this.carAndPeopleImg.length;
      if (!type) {
        // 若不符合图片类型，则让当前上传的文件去除掉即可，即从上传对列删除本次上传
        this.carAndPeopleImg.splice(fileLength - 1, 1);
        this.$message.error('只允许上传图片');
        return
      }
      this.uploadDisabled1 = fileList.length >= this.limitNum
    },
    //上传限制文件类型 支付凭证
    uploadChange1(file, fileList) {
      // 拿到当前上传图片的name，判断其后缀名是否符合
      let type = this.isImage(file.name);
      this.payImgList = fileList;
      let fileLength = this.payImgList.length;
      if (!type) {
        // 若不符合图片类型，则让当前上传的文件去除掉即可，即从上传对列删除本次上传
        this.payImgList.splice(fileLength - 1, 1);
        this.$message.error('只允许上传图片');
        return
      }
      this.uploadDisabled2 = fileList.length >= this.limitNum

    },
    // 判断是否是图片
    isImage(fileName) {
      if (typeof fileName !== 'string') return;
      let name = fileName.toLowerCase();
      return name.endsWith('.png') || name.endsWith('.jpeg') || name.endsWith('.jpg') || name.endsWith('.png');
    }
  },
  created() {
    //获取全部运单列表
    this.getAllBillList()
    //获取场景类型列表
    this.getSceneList()
    //获取货品单位列表
    this.getGoodsUnitList()
  },

}
</script>

<style>
.vehicleType .el-dialog {
  top: -10%;
}

.v-modal {
  z-index: 2000 !important;
}

.hide .el-upload--picture-card {
  display: none;
}
</style>

<style scoped lang="scss">
@import '../../../../assets/style/variable.scss';

.mpWaybillList {
  .picArr1 {

    // padding-left: 30px;
    .title {
      margin-top: 30px;
      padding-bottom: 20px;
      padding-left: 30px;
      border-bottom: 1px solid #f5f5f5;
    }

    .upload {
      margin-left: 60px;
      margin-top: 30px;
    }
  }

  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }

  .el-form-item {
    margin-bottom: 14px;
  }

  .head {
    display: flex;

    .btn {
      vertical-align: top;
      margin: 15px 0 0 16px;
    }
  }

  .vehicleType {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
      padding-top: 28px !important;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .uploadArea {
      margin-top: 20px;

      .confirmInfoForm {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .hint {
      display: flex;
      justify-content: center;
    }

    .choose {
      display: flex;
      justify-content: center;
    }
  }

  .btns {
    margin-bottom: 20px;
  }

  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }

  .addForm {
    display: flex;
    flex-wrap: wrap;

    .el-form-item {
      width: 450px;
    }

    .el-date-editor {
      width: 100% !important;
    }
  }

  .pagination {
    margin-top: 10px;
  }
}</style>