<template>
  <div class="mpWaybillList">
    <!-- 表格区域 -->
    <div class="facilityBox" style="position: relative">
      <el-tabs v-model="activeCmpt" @tab-click="cmptChange">
        <el-tab-pane label="所有运单" name="1"></el-tab-pane>
        <!-- <el-tab-pane label="工运宝运单" name="4"></el-tab-pane> -->
        <!-- <el-tab-pane label="快捷录单运单" name="2"></el-tab-pane> -->
        <!-- <el-tab-pane label="外部平台运单" name="3"></el-tab-pane> -->
      </el-tabs>

      <!-- 温馨提示 -->
      <div style="position: absolute; top: 17px; right: 16px">
        <el-tooltip placement="left">
          <div slot="content">
            快捷录单、外部平台等来源的运单，需要完善信息后，才能发起流程
          </div>
          <div style="margin: 0px 20px; color: #0f5fff; cursor: pointer">
            <i class="el-icon-question"></i>
            <span>帮助</span>
          </div>
        </el-tooltip>
      </div>

      <!-- 所有运单 -->
      <allBill v-if="activeCmpt == 1"></allBill>
      <!-- 工运宝运单 -->
      <constructionBillCmpt v-if="activeCmpt == 4"></constructionBillCmpt>
      <!-- 运税宝小程序运单，快捷录单运单 -->
      <MpWaybillCmpt v-if="activeCmpt == 2"></MpWaybillCmpt>
      <!-- 好伙伴运单 -->
      <OtherWayBillCmpt v-if="activeCmpt == 3"></OtherWayBillCmpt>
    </div>
  </div>
</template>

<script>
import allBill from "./waitWayBillCmpt/allBill"; // 所有运单
import MpWaybillCmpt from "./waitWayBillCmpt/mpWaybillCmpt"; // 运税宝小程序运单，快捷录单运单
import OtherWayBillCmpt from "./waitWayBillCmpt/otherWayBillCmpt"; // 好伙伴运单
import ConstructionBillCmpt from "./waitWayBillCmpt/constructionBillCmpt"; // 工运宝运单
export default {
  data() {
    return {
      activeCmpt: "1", // tab栏切换
    };
  },

  methods: {
    //tabs页面跳转
    cmptChange(tab) {
      this.activeCmpt = tab.name;
    },
  },
  components: {
    MpWaybillCmpt,
    OtherWayBillCmpt,
    allBill,
    ConstructionBillCmpt,
  },
  created() {},
};
</script>

<style lang="scss">
.mpWaybillList {
  .el-dialog {
    top: -10%;
  }
}

.v-modal {
  z-index: 2000 !important;
}
</style>

<style scoped lang="scss">
@import "../../../assets/style/variable.scss";

.mpWaybillList {
  .picArr1 {
    // padding-left: 30px;
    .title {
      margin-top: 30px;
      padding-bottom: 20px;
      padding-left: 30px;
      border-bottom: 1px solid #f5f5f5;
    }

    .upload {
      margin-left: 60px;
      margin-top: 30px;
    }
  }

  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }

  .el-form-item {
    margin-bottom: 14px;
  }

  .head {
    display: flex;

    .btn {
      vertical-align: top;
      margin: 15px 0 0 16px;
    }
  }

  .vehicleType {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
      padding-top: 28px !important;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .uploadArea {
      margin-top: 20px;

      .confirmInfoForm {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .hint {
      display: flex;
      justify-content: center;
    }

    .choose {
      display: flex;
      justify-content: center;
    }
  }

  .btns {
    margin-bottom: 20px;
  }

  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }

  .addForm {
    display: flex;
    flex-wrap: wrap;

    .el-form-item {
      width: 450px;
    }

    .el-date-editor {
      width: 100% !important;
    }
  }

  .pagination {
    margin-top: 10px;
  }
}
</style>
